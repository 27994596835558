import request from '@/utils/request'
// 支付
export function pay(data) {
  return request({
    url: '/payOrder/pay',
    method: 'post',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 新增聊天记录
export function studyRecord(data) {
  return request({
    url: '/student/studyRecord/add',
    method: 'post',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 课程目录
export function content(data) {
  return request({
    url: 'course/stu/course/content' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 课程资料
export function courseDocs(data) {
  return request({
    url: 'course/stu/courseDocs' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 课程资料
export function download(data) {
  return request({
    url: 'course/stu/doc/download' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// /course/stu/courseDoc/check/{docId}
export function courseCheck(data) {
  return request({
    url: 'course/stu/courseDoc/check' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 课程详情
export function detail(data) {
  return request({
    url: 'payOrder/course/detail' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 支付方式
export function payTypes(data) {
  console.log(data)
  return request({
    url: '/payOrder/payTypes',
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 微信支付状态
export function queryOrder(data) {
  console.log(data)
  return request({
    url: '/payOrder/wx/queryOrder' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
